import React, { useState } from "react";
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { lightTheme, darkTheme } from '../styles/theme';
import Home from './pages/Home'
import Messages from "./pages/Messages";
import Message from "./pages/Message";

const App = () => {
    const [theme, setTheme] = useState(lightTheme);

  const toggleTheme = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

    return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path='/' exact Component={Home}></Route>
              <Route path='/messages' Component={Messages}></Route>
              <Route path='/messages/:message' Component={Message}></Route>
            </Routes>
          </BrowserRouter>
    
        </ThemeProvider>
    );
}

export default App;