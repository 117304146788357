export const LOGIN = 'login';
export const REFRESH_TOKEN = 'auth/refresh-token';

export const INDEX_MESSAGES = 'users/developer@ugbanawaji.com/messages';

export const INDEX_USER = 'users';
export const CREATE_USER = 'register';
export const UPDATE_USER = 'update';
export const GET_USER = 'users/';
export const DELETE_USER = 'users/';
// Add other endpoints as needed
