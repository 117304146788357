import React from "react";
import Container from '../atoms/Container'
import { connect } from "react-redux";
import { getMessage } from "../../redux/actions/messageActions";
import Button from "../atoms/Button";


class Home extends React.Component{
    componentDidMount(){
        this.props.getMessage();
        
    }

    render(){
        const { readMessage, messages } = this.props;
        return (
            <Container style={{display: 'flex', justifyContent:'center',  height: '100vh'}}>
                <div style={{  display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'}}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{marginBottom: '3rem'}}>
                            <h3>Hello Jim</h3>
                        </div>
                        <div style={{marginBottom: '2rem'}}>
                            <p>You have {readMessage} unread messages out of {messages?.length} total</p>
                        </div>
                        <div>
                            <Button href="/messages" as={'a'} style={{color:'white', textDecoration: 'none'}}>View Messages</Button>
                        </div>
                    </div>
                </div>
                
            </Container>
        );
    }
}
const mapStateToProps =(state, ownProps) =>{
    return {
        messages: state.messages,
        user: state.auth,
        readMessage: state.messages.filter(m => m.is_read !== true).length,
    }
}
export default connect(mapStateToProps,{getMessage})(Home)