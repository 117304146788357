import { CREATE_MESSAGE, DELETE_MESSAGE, INDEX_MESSAGE, TOGGLE_MESSAGE } from "../actions/messageActions"
  
const messages = (state = [], action) => {
    switch(action.type) {
      case CREATE_MESSAGE :
        return state.concat([action.payload])
      case DELETE_MESSAGE :
        return state.filter((message) => message.id !== action.id)
      case TOGGLE_MESSAGE :
        return state.map((message) => message.id !== action.id ? message :
          Object.assign({}, message, { complete: !message.is_read }))
      case INDEX_MESSAGE :
        return action.payload
      default :
        return state
    }
  }

  export default messages;