import api from '../../api/axiosConfig'
import { GET_USER } from '../../api/endpoints';
import { create } from './actionCreators'

export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const getUser = (id)=>{
    return async (dispatch, getState) =>{
        const response = await api.get(GET_USER`${id}`);
        dispatch(create(CREATE_USER, response.data));
    }
}