import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
`;

export default Container;