import { CREATE_USER } from "../actions/userActions";
  
const userReducers = (state = [], action) => {
    switch(action.type) {
      case CREATE_USER :
        return state.concat([action.payload])
      default :
        return state
    }
  }

  export default userReducers;