
import { applyMiddleware } from "redux";
import { createStore } from "redux"
import { combineReducers } from "redux";
import {thunk} from 'redux-thunk';
import messageReducer from './messageReducers';
import userReducer from "./userReducers";



const rootReducer  = combineReducers({
    messages: messageReducer,
    auth: userReducer,
})


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;