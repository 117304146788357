export const lightTheme = {
    primaryColor: '#001AF1',
    backgroundColor: '#D2E1F1',
    textColor: '#000',
    borderColor: '#1C1E1F',
    hoverColor: '#0056b3'
  };
  
  export const darkTheme = {
    primaryColor: '#1e1e1e',
    backgroundColor: '#333',
    textColor: '#fff',
    borderColor: '#555',
    hoverColor: '#000'
  };
  