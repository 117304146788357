import api from '../../api/axiosConfig'
import { INDEX_MESSAGES} from '../../api/endpoints';
import { index, destroy, create, toggle } from './actionCreators'

export const INDEX_MESSAGE = 'INDEX_MESSAGE';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const TOGGLE_MESSAGE = 'TOGGLE_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';


export const getMessage = () => {
    return (dispatch) => {
        api.get(INDEX_MESSAGES).then((response)=>{
          dispatch(index(INDEX_MESSAGE,response.data.data))
        }).catch(error=>{
          console.log(error.message)
          alert(`something went wrong ${error.message}`)
        })
        
    }
}
export const showMessage = (messageId) => {

  return (dispatch) => {
      api.get(INDEX_MESSAGES + `/${messageId}`).then((response)=>{
        dispatch(create(CREATE_MESSAGE,response.data.data))
        dispatch(toggle(TOGGLE_MESSAGE,messageId))
        toggleMessage(messageId);
      }).catch(error=>{
        console.log(`something went wrong ${error.message}`)
      })
      
  }
}
export const deleteMessage = ( message ) => {
    return (dispatch) => {
      dispatch(destroy(DELETE_MESSAGE,message.id))
  
      return api.delete(message.id)
        .catch(() => {
          dispatch(create(CREATE_MESSAGE,message))
          alert('An error occurred. Try again.')
        })
    }
  }
  
  export const toggleMessage = (id) => {
    console.log(id)
    return (dispatch, getState) => {
      
      const message = getState().messages.find(({id}) =>  id === parseInt(id));
      console.log(message, 'message')
      dispatch(toggle(TOGGLE_MESSAGE,id))
        return api.patch(INDEX_MESSAGES + `/${id}`,{
          is_read: true
        }).then(response =>{
          console.log(response.data)
        })
          .catch(() => {
            dispatch(toggle(TOGGLE_MESSAGE,id))
            alert('An error occurred. Try again.')
          })
      
    }
  }