import React from "react";
import Container from '../atoms/Container'
import { connect } from "react-redux";
import { getMessage } from "../../redux/actions/messageActions";
import { useNavigate } from "react-router-dom";

export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
  }
class Messages extends React.Component{
    componentDidMount(){
        this.props.getMessage();
 
    }
    navigate = (messageId) =>{
        
        this.props.navigate(`/messages/${messageId}`)
    }

    render(){
        const { messages } = this.props;
        if(!messages){
            return <container>...loading</container>
        }
        return (
            <Container style={{display: 'flex', justifyContent:'center', backgroundColor: 'white'}}>
               <div style={{padding: '3rem'}}>
                <h1 style={{padding: '0.5rem'}}>Messages</h1>
                <Container>
                    {messages.map(message =>{
                        return (
                        <div onClick={() => this.navigate(message.id)} key={message.id} style={{borderBottom: '1px solid #000', cursor: 'pointer'}}>
                            <div style={{textAlign: 'right', paddingTop: '0.5rem'}}>
                                <span style={{backgroundColor: '#262626', color: 'white', padding: '0.5rem 1rem'}}>{
                                    message.is_read ? 'read' : 'unread'
                                }</span>
                            </div>
                            <h3>{message.subject}</h3>
                            <p>{message.content}</p>
                        </div>
                        );
                    })}
                </Container>
               </div>
            </Container>
        );
    }
}
const mapStateToProps =(state, ownProps) =>{
    return {
        messages: state.messages,
    }
}
export default connect(mapStateToProps,{getMessage})(withNavigation(Messages))