import React from "react";
import Container from '../atoms/Container'
import { connect } from "react-redux";
import { showMessage } from "../../redux/actions/messageActions";
import { useParams } from "react-router-dom";

export const  withParams = (Component) => {
    return props => <Component {...props} messageId={useParams()} />;
  }
class Message extends React.Component{
    componentDidMount(){

        this.props.showMessage(this.props.messageId.message);
        //this.props.toggleMessage(this.props.messageId.message)
    }

    render(){

        const message = this.props.messages.find(({id}) =>  id === parseInt(this.props.messageId.message));

        if(!message){
            return <Container>...loading</Container>
        }
        
        return (
            <Container style={{display: 'flex', justifyContent:'center',  height: '100vh', backgroundColor: 'white'}}>
               <div style={{padding: '3rem'}}>
                <h2>{message.subject}</h2>
                <Container>
                    {<div style={{paddingBottom: '2rem', fontWeight:'600'}}>

                            <p>{message.content}</p>
                        </div>
                        }
                </Container>
               </div>
            </Container>
        );
    }
}
const mapStateToProps =(state, ownProps) =>{
    console.log(state)
    return {
        messages: state.messages,
    }
}
export default connect(mapStateToProps,{showMessage})(withParams(Message))