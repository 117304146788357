export const index = ( type, payload ) => {
    return {
      type: type,
      payload,
    }
}
export const update =  ( type, payload ) => {
    return {
      type: type,
      payload,
    }
  }

export const destroy =  ( type, id ) => {
    return {
      type: type,
      id,
    }
  }
  
export const toggle = (type, id) => {
    return {
      type: type,
      id,
    }
}

export const create = (type, payload) => {
    return {
      type: type,
      payload,
    }
}